<template>
  <div class="main" data-title="销售目标设置" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">销售目标设置</h2>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div @click="$router.push({name: 'database-config'})" class="tab">基础数据配置</div>
        <div @click="$router.push({name: 'database-custom'})" class="tab">自定义字段</div>
        <!-- <div @click="$router.push({name: 'database-reviewer'})" class="tab">战败审核人</div> -->
        <div @click="$router.push({name: 'database-kpi'})" class="tab curr">销售目标设置</div>
      </div>
    </div>

    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <div class="right-main">
          <div class="head">
            <div class="head-title">销售目标</div>
            <div class="head-btns">
              <el-date-picker
                @change="changeMonth"
                format="yyyy年MM月"
                placeholder="选择月"
                style="margin-right: 15px;"
                type="month"
                v-model="month"
                value-format="yyyyMM"
              ></el-date-picker>
              <el-button @click="add" class="btn" icon="el-icon-edit" type="primary">新建</el-button>
            </div>
          </div>

          <div class="body">
            <div class="no-data" v-if="listData.length == 0">
              暂无数据，你可以
              <br />
              <el-button @click="copy" type="text">一键复制上月数据</el-button>
            </div>

            <div class="table-wrapper" v-else>
              <el-table :data="listData" class="table" style="width: 100%">
                <el-table-column label="销售顾问" prop="sell_uid">
                  <template slot-scope="scope">{{$store.state.userList[scope.row.uid][0]}}</template>
                </el-table-column>
                <el-table-column label="年月" prop="month">
                  <template slot-scope="scope">{{scope.row.month}}</template>
                </el-table-column>
                <el-table-column label="订单目标">
                  <template slot-scope="scope">{{scope.row.order_quantity}}</template>
                </el-table-column>
                <el-table-column label="交车目标">
                  <template slot-scope="scope">{{scope.row.delivery_quantity}}</template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100px">
                  <template slot-scope="scope">
                    <el-button @click="edit(scope.$index, scope.row)" size="small" type="text">编辑</el-button>
                    <el-button
                      @click="deleteItem(scope.$index, scope.row)"
                      class="btn-delete no-bg"
                      size="small"
                      type="text"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <edit-kpi :month="month" ref="editKpi"></edit-kpi>
  </div>
</template>
<script>
import { dateFormat, monthFormat2 } from '@/unit/unit.js'
import EditKpi from '@/components/dashboard/EditKpi.vue'
export default {
  components: {
    EditKpi
  },
  data() {
    return {
      month: monthFormat2(),
      rules: {
        f1: [{ required: true, message: '必需填写', trigger: 'change' }]
      },
      listData: [],
      sellArr: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // for((item, key) in this.$store.state.userList){
      //   console.log(item)
      // }
      this.setInit()
    },

    //初始化销售顾问数据
    setInit() {
      //读取当月数据
      this.getListData()

      //获取销售顾问
      // Object.entries(this.$store.state.userList).map((item, key) => {
      //   if (item[1][1] == 2 && item[1][2] == 1) {
      //     this.listData.push({
      //       sell_uid: key,
      //       month: this.month,
      //       d1: 0,
      //       d2: 0,
      //       d3: 0
      //     })
      //   }
      // })
    },

    changeMonth() {
      this.listData = []
      let month = this.month
      this.getListData()
    },

    //一键copy上月数据
    copy() {
      this.$axios
        .post('/target/duplicate', {
          month: this.month
        })
        .then(res => {
          if (res.data.code == 200) {
            this.getListData()
          }
        })
    },

    add() {
      this.$refs.editKpi.show()
    },
    edit(index, item) {
      this.$refs.editKpi.edit(item)
    },
    deleteItem(index, item) {
      this.$confirm('此操作将永久删除销售目标, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post('/target/remove', {
              target_id: item.target_id
            })
            .then(res => {
              if (res.data.code == 200) {
                this.listData.splice(index, 1)
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //获取数据列表
    getListData() {
      this.$axios
        .post('/target/lists', {
          month: this.month,
          page: 1,
          count: 99999
        })
        .then(res => {
          if (res.data.code == 200) {
            this.listData = res.data.data.list
          }
        })
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/cfgdata/create', this.ruleForm).then(res => {
            if (res.data.code == '200') {
              this.getListData()
              this.$message.success(res.data.msg)
              this.visible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      // Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}

.ruleForm {
  padding: 0 80px;
}

.left-menu {
  border-right: 1px solid #e8e8e8;
  .left-menu-item {
    cursor: pointer;
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
    &:hover {
      color: #409eff;
    }
    &.curr {
      background: #e6f7ff;
      font-weight: 500;
      color: #409eff;
      border-right: 3px #409eff solid;
    }
  }
}
</style>