<template>
  <el-dialog :visible.sync="visible" title="添加">
    <el-form :model="form" :rules="rules" class="form" label-width="100px" ref="form" status-icon>
      <el-form-item label="销售顾问:" prop="seller_uid">
        <el-select filterable placeholder="请选择" v-model="form.seller_uid">
          <template v-for="(item, key) in $store.state.userList">
            <el-option
              :key="key"
              :label="item[0]"
              :value="parseInt(key)"
              v-if="item[3] == 1"
            ></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="所选月份:">
        {{month}}
      </el-form-item>
      <el-form-item label="订单目标:">
        <el-input-number :min="1" controls-position="right" v-model="form.order_quantity"></el-input-number>
      </el-form-item>
      <el-form-item label="交车目标:">
        <el-input-number :min="1" controls-position="right" v-model="form.delivery_quantity"></el-input-number>
      </el-form-item>
    </el-form>

    <span class="dialog-footer" slot="footer">
      <el-button @click="resetForm('form')">取 消</el-button>
      <el-button @click="submitForm('form')" type="primary">提 交</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ['month'],
  data() {
    return {
      visible: false,
      type: 'create',
      form: {
        seller_uid: '',
        uids: [],
        month: this.month,
        order_quantity: 1,
        delivery_quantity: 1
      },
      rules: {
        seller_uid: [{ required: true, message: '必需填写', trigger: 'change' }]
      }
    }
  },
  methods: {
    show() {
      this.type = 'create'
      this.visible = true
    },
    edit(item){
      this.type = 'edit'
      this.form.target_id = item.target_id
      this.form.seller_uid = item.uid
      this.form.order_quantity = item.order_quantity
      this.form.delivery_quantity = item.delivery_quantity
      this.visible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.form.uids = []
          this.form.uids.push(this.form.seller_uid)
          this.$axios.post('/target/'+this.type, this.form).then(res => {
            if (res.data.code == '200') {
              this.$parent.getListData()
              this.$message.success(res.data.msg)
              this.visible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0 80px;
  /deep/ .el-select {
    width: 100%;
  }
}
</style>